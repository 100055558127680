"use client";

import {Session} from "next-auth";
import {SessionProvider, signIn, signOut} from "next-auth/react";
import React, {useEffect, useState} from "react";

import {authContext} from "./auth-context";

interface AuthProviderProps {
  children: React.ReactNode;
  defaultSession?: { status: string; data: Session } | null;
}

const Provider: React.FC<AuthProviderProps> = ({children, defaultSession}) => {
  const [activeSession, setActiveSession] = useState<{ status: string; data: Session } | null>(defaultSession || null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = async (creds?: any) => {
    try {
      return await signIn("credentials", creds);
    } catch (error) {
      console.warn(error);
    }
  };

  const logout = async () => {
    await signOut();
  };

  useEffect(() => {
    if (defaultSession) setActiveSession(defaultSession);
  }, [defaultSession]);

  return <authContext.Provider value={{user: activeSession?.data?.user, isAuthenticated, login, logout}}>{children}</authContext.Provider>;
};

const AuthProvider: React.FC<AuthProviderProps> = ({children}) => {
  return (
    <SessionProvider>
      <Provider>{children}</Provider>
    </SessionProvider>
  );
};

export default AuthProvider;
